import React, { useState, useEffect, ReactNode } from 'react'
import * as api from '../../services/api'
import { useAuth } from "../../hooks/useAuth"

import Blocked from "./Blocked"
import { LoadingPage } from "../../components/LoadingPage"

import { toast } from "react-toastify"

type OnboardingsssProps = {
    children: ReactNode;
}

export default function ProtectRoutesOnboarding({children}: OnboardingsssProps) {

    const { user } = useAuth()

    const [loadingPage, setLoadingPage] = useState<boolean>(true)
    const [tokenOnboarding, setTokenOnboarding] = useState<boolean>(false)

    useEffect(() => {
        const companyId = user?.corporate_id!;
        //const user_id = user?.id!
        const fetchData = async () => {
            try {
                //const getTokenRequest = await api.get(`company/retrieve/${companyId}/`) as { [key: string]: any };
                //const getPermissionOnboardingRequest = await api.get(`user/list_permissions/?user_id=${user_id}&permission_name=Onboarding`) as { [key: string]: any };
//
                //const [getToken, getPermissionOnboarding] = await Promise.all([getTokenRequest, getPermissionOnboardingRequest])
                //const tokenOnboardingApi = getToken.content.token_onboarding;
                //const using_onboarding = getToken.content.using_onboarding;
//
                //if (!using_onboarding || getPermissionOnboarding.content !== "Permissão Onboarding encontrada") {
                //    setTokenOnboarding(false);
                //    setLoadingPage(false);
                //    return;
                //}

                const getToken = await api.get(`company/retrieve/${companyId}/`) as { [key: string]: any };
                const tokenOnboardingApi = getToken.content.token_onboarding;
                const using_onboarding = getToken.content.using_onboarding;
    
                if (!using_onboarding) {
                    setTokenOnboarding(false);
                    setLoadingPage(false);
                    return;
                }
    
                setTokenOnboarding(tokenOnboardingApi);
                setLoadingPage(false);
    
            } catch (error) {
                toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingPage(false);
            }
        };
    
        fetchData();
    }, [user?.corporate_id]);
    

    if (loadingPage) {
        return <LoadingPage />;
    }

    if (!tokenOnboarding) {
        return <Blocked />;
    }

    return (
        <>
            {children}
        </>
    )

}


