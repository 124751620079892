import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useItemsStore } from "../../../../contexts/zustand/onboarding"
import { v4 as uuidv4 } from "uuid";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast, ToastContainer } from "react-toastify";

import {
    Paper,
    Box,
    InputAdornment,
    TextField,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    Grid,
    Tooltip,
    FormControlLabel
} from '@mui/material';

import ImportExportIcon from '@mui/icons-material/ImportExport';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import Checkbox from '@mui/material/Checkbox';

import ProtectRoutesOnboarding from "../../index";

import { useAuth } from "../../../../hooks/useAuth";
import { useCore } from "../../../../hooks/useCore";
import * as api from '../../../../services/api';

import { DraggableItem } from "../../../../types/onboarding";
import { choicesFields, optionsFields, optionsAttachments, optionSection } from "../../ChoicesFields/choices";
import SendOnboarding from "../../SendOnboarding";
import EditFieldOptions from "../../Editfield";

type ItemContent = {
    content: {
        id: string;
        created_at: string;
        name_model: string;
        title_model: string;
        model_onboarding: any[]
    }
};

type SelectChangeEvent3 = {
    target: {
        name: string;
        checked: boolean;
    };
}

export default function EditOnboardingSaved() {

    const { user } = useAuth()

    const { items, addItems, updateItems, removeItems, clearFormValues } = useItemsStore()

    const navigate = useNavigate()

    const DRAGGABLE_ITEM_TYPE = "draggable-item";
    const { id_onboarding } = useParams();

    const { setTitleBar, setPathTitleBar } = useCore();

    const [openSendOboarding, setOpenSendOboarding] = useState<boolean>(false);
    const [openEditFiled, setOpenEditFiled] = useState<boolean>(false);
    const [getValueKey, setGetValueKey] = useState<string>("");
    const [typeEditField, setTypeEditField] = useState<number>(0);

    const [checkedAllRequiredQuestions, setCheckedAllRequiredQuestions] = useState<boolean>(false);

    const [nameForm, setNameForm] = useState<string>("Nome do Onboarding");
    const [titleForm, setTitleForm] = useState<string>("Título do Onboarding");

    const [expanded, setExpanded] = useState<string | false>(false);

    useLayoutEffect(() => {
        setTitleBar("Onboarding");
        setPathTitleBar("");
    }, [setPathTitleBar, setTitleBar]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseOnboarding = await api.get(`/onboarding/list/${id_onboarding}/`) as { [key: string]: any }

                const modelOnboarding = responseOnboarding as ItemContent

                setNameForm(modelOnboarding.content.name_model)
                setTitleForm(modelOnboarding.content.title_model)
                clearFormValues()
                updateItems(modelOnboarding.content.model_onboarding)

            } catch (error) {
                toast.error('Erro ao carregar informações!!!');
            }
        };

        fetchData();

    }, [id_onboarding]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const validateOnboardingForm = (): boolean => {
        if (nameForm === 'Nome do Onboarding') {
            toast.dismiss();
            toast.error('Digite o nome do onboarding!!!');
            return false;
        }

        if (titleForm === 'Título do Onboarding') {
            toast.dismiss();
            toast.error('Digite o título do onboarding!!!');
            return false;
        }

        const typesToCheck = ['13', '15', '16'];

        const updatedItems = items.map((item) => {
            let error = '';

            if (typesToCheck.includes(item.type as string) && (!item.options || item.options.length < 2)) {
                error = 'Adicione pelo menos 2 opções nos campos multi escolha';
            } else if (!item.label) {
                error = 'Adicione um nome';
            }

            return { ...item, error };
        });

        updateItems(updatedItems);

        const hasError = updatedItems.some(item => item.error && item.error !== '');

        if (hasError) toast.error('Existem campos não preenchidos', {autoClose: 2000})

        return !hasError;

    };

    const handleSendOnboadingOpen = () => {
        const checkNameTitleForm = validateOnboardingForm()

        if (!checkNameTitleForm) return

        if (items.length > 0) {
            setOpenSendOboarding(true);
        } else {
            toast.dismiss()
            toast.error('Adicione pelo menos 1 campo para enviar o formulário');
        }
    };

    const handleEditFieldOpen = (uuidKey: string, typeEditField: number) => {
        setOpenEditFiled(true);
        setGetValueKey(uuidKey);
        setTypeEditField(Number(typeEditField));
    };

    const handleNameForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valueText = event.target.value;

        if (valueText.length <= 90) {
            setNameForm(valueText);
        } else if (valueText === "") {
            setNameForm("Nome do Onboarding");
        }
    };

    const handleTitleForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valueText = event.target.value;

        if (valueText.length <= 90) {
            setTitleForm(valueText);
        } else if (valueText === "") {
            setTitleForm("Título do Onboarding");
        }
    };

    const handleDragEnd = (item: DraggableItem) => {
        addItems({
            ...item,
            required: checkedAllRequiredQuestions ? false : true 
        })
    };

    const handleDeleteField = (idToRemove: string) => {
        removeItems(idToRemove)
    };

    const handleItemDropped = (item: DraggableItem) => {
        //console.log('Item dropped: ', ok[item?.id as any].value, item);
    };

    const sendValuesFields = (fields: {
        label: { [key: string]: string };
        options: { [key: string]: string[] };
        defaultValue: string;
        valueRequired: { [key: string]: boolean };
        key: string;
    }) => {
        const { label, key, options, valueRequired } = fields;

        const updatedItems = items.map((item) => {
            if (item.key === key) {
                const currentLabel = label[key]
                const currentOptions = options[key] || [];

                let errorMessage = '';
                const isExcludedType = ['13', '15', '16'].includes(item.type!);

                if (!currentLabel) {
                    if (isExcludedType) {
                        if (!currentOptions || currentOptions.length < 2) {
                            errorMessage = 'Adicione um nome - Adicione pelo menos 2 opções nos campos multi escolha';
                        } else {
                            errorMessage = 'Adicione um nome';
                        }
                    } else {
                        errorMessage = 'Adicione um nome';
                    }
                }

                if (currentLabel && (!currentOptions || currentOptions.length < 2) && isExcludedType) {
                    if (!errorMessage) {
                        errorMessage = 'Adicione pelo menos 2 opções nos campos multi escolha';
                    }
                }

                return {
                    ...item,
                    label: currentLabel,
                    required: valueRequired[key],
                    options: currentOptions,
                    error: errorMessage
                };
            }
            return item;
        });

        updateItems(updatedItems);
    }

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        if (dragIndex === hoverIndex) {
            return;
        }

        if (dragIndex < 0 || dragIndex >= items.length || hoverIndex < 0 || hoverIndex >= items.length) {
            return;
        }

        const newItems = [...items];
        const [removed] = newItems.splice(dragIndex, 1);
        newItems.splice(hoverIndex, 0, removed);

        updateItems(newItems);
    };

    const DraggableTooltip = ({ item, onDragEnd }: any) => {

        const addField = (type: string, id:string) => {

            const existingItem = items.find((selectedItem) => selectedItem.type === '12');

            if (!existingItem && type === '12') {
                alert('ATENÇÃO : Esse campo restringe a resposta do onboarding para que seja acessado somente pelo celular');
            }

            if (id === '6') {
                const additionalItems = optionsFields.filter(field => ['2', '3','4', '5'].includes(field.id));

                additionalItems.forEach(field => {
                    addItems({
                        ...field,
                        required: checkedAllRequiredQuestions ? false : true
                    });
                });
            }

            addItems({
                ...item,
                required: checkedAllRequiredQuestions ? false : true 
            })
        };

        const [{ isDragging }, drag] = useDrag(() => ({
            type: DRAGGABLE_ITEM_TYPE,
            item: { id_choice: item.id_choice, label: item.label, type: item.type },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult) {

                    const existingItem = items.find(
                        (selectedItem) => selectedItem.type === '12'
                    );

                    if (!existingItem && item.type === '12') {
                        alert('ATENÇÃO : Esse campo restringe a resposta do onboarding para que seja acessado somente pelo celula');
                    }

                    onDragEnd?.(item);
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }));

        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    margin: 1,
                    backgroundColor: '#FFA890',
                    borderRadius: '16px',
                    padding: '4px 8px',
                    color: '#FFFFFF',
                    cursor: 'pointer',
                    border: isDragging ? '2px solid red' : 'none',
                    '&:hover': {
                        backgroundColor: '#FFA890 !important',
                        opacity: 0.9,
                    },
                }}
            >
                <Chip
                    label={item.label}
                    sx={{
                        fontSize: 16,
                        backgroundColor: '#FFA890',
                        color: '#FFFFFF',
                        opacity: isDragging ? 0.5 : 1,
                        boxShadow: 'none !important',
                        transform: 'none !important',
                        '&:hover': {
                            backgroundColor: '#FFA890',
                            opacity: 0.9,
                        },
                    }}
                    ref={drag}
                    onClick={(e) => {
                        e.stopPropagation();
                        addField(item.type, item.id);
                    }}
                />
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        addField(item.type, item.id);
                    }}
                    startIcon={<AddIcon />}
                    sx={{
                        minWidth: 0,
                        marginLeft: 0.5,
                        padding: '3px',
                        color: '#FFFFFF',
                        borderRadius: '16px',
                        "& .MuiButton-startIcon": {
                            border: '2px solid #FFFFFF',
                            borderRadius: '50%',
                            width: '22px',
                            height: '22px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        "&:hover": {
                            "& .MuiButton-startIcon": {
                                backgroundColor: '#FFA890',
                                color: '#E0E0E0',
                            },
                        },
                    }}
                />
            </Box>
        );
    };

    const DroppableArea = ({ children, onItemDropped }: any) => {
        const [, drop] = useDrop({
            accept: DRAGGABLE_ITEM_TYPE,
            drop: onItemDropped,
        });

        return (
            <div ref={drop} style={{ width: "100%", minHeight: "250px", backgroundColor: "#eee" }}>
                {children}
            </div>
        );
    };

    const DraggableItem = ({ item, index, moveItem }: any) => {

        const ref = useRef<HTMLElement>(null);
        const [, drop] = useDrop({
            accept: DRAGGABLE_ITEM_TYPE,
            hover(itemDragged: any, monitor) {
                if (!ref.current) {
                    return;
                }
                const dragIndex = itemDragged.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }

                const hoverBoundingRect = ref.current.getBoundingClientRect();
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset()!;
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }

                moveItem(dragIndex, hoverIndex);
                itemDragged.index = hoverIndex;
            },
        });

        const [, drag] = useDrag({
            type: DRAGGABLE_ITEM_TYPE,
            item: { type: DRAGGABLE_ITEM_TYPE, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        drag(drop(ref));

        return (
            <Box
                ref={ref}
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    margin: "20px 0 0 15px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: 'center', marginRight: '8px' }}>
                    <ImportExportIcon fontSize={'medium'} sx={{ color: '#FFA089', height: '45px', width: '24px' }} />
                </Box>

                <Box sx={{ flexGrow: 1, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    {choicesFields[item.id_choice].value(item)}
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", marginLeft: '8px' }}>
                    <Button onClick={() => handleEditFieldOpen(item.key, item.id_choice)} sx={{ minWidth: 'auto', padding: 0 }}>
                        <DriveFileRenameOutlineIcon
                            sx={{
                                backgroundColor: '#FFFFFF',
                                border: '2px solid #D6D6D6',
                                borderRadius: '12px',
                                padding: '7px',
                                height: '45px',
                                width: '40px',
                                color: '#FFA089'
                            }} />
                    </Button>
                    <Button onClick={() => handleDeleteField(item.key)} sx={{ minWidth: 'auto', padding: 0, marginLeft: '8px' }}>
                        <HighlightOffIcon
                            sx={{
                                backgroundColor: '#FFFFFF',
                                border: '2px solid #D6D6D6',
                                borderRadius: '12px',
                                padding: '7px',
                                height: '45px',
                                width: '40px',
                                color: '#FFA089'
                            }} />
                    </Button>
                </Box>
            </Box>
        );
    };

    const allRequiredQuestions = async (e: SelectChangeEvent3) => {
        const checked = e.target.checked
        setCheckedAllRequiredQuestions(checked)

        if (checked) {
            const updateItem = items.map(item => ({
                ...item,
                required: false
            }));

            updateItems(updateItem)
        } else {
            const updateItem = items.map(item => ({
                ...item,
                required: true
            }));

            updateItems(updateItem)
        }
    };

    const AddSession = () => {
        addItems(optionSection)
    }

    const SavedOnboarding = async () => {

        const checkNameTitleForm = validateOnboardingForm()

        if (!checkNameTitleForm) return

        if (items.length === 0) {
            toast.dismiss()
            return toast.error('Adicione pelo menos 1 campo para salvar o formulário');
        }

        let position = 0;
        const itemsQuestions = items.map(ok => {
            return {
                ...ok,
                position: position++
            };
        });

        const data = {
            'created_by': user?.id,
            'company': user?.corporate_id,
            'name_model': nameForm,
            'title_model': titleForm,
            'model_onboarding': itemsQuestions
        }

        const response = await api.put(`/onboarding/update/${id_onboarding}/`, data) as { [key: string]: any };

        if (response.status === 200) {
            toast.success('Onboarding Salvo com Sucesso!!!');
        } else if (response.response.status === 400) {
            toast.error(`${response.response.data.content.message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <ProtectRoutesOnboarding>
            <ToastContainer />

            {<SendOnboarding open={openSendOboarding} setOpen={setOpenSendOboarding} nameModelOnboarding={nameForm} titleOnboarding={titleForm} />}
            {<EditFieldOptions open={openEditFiled} setOpen={setOpenEditFiled} refKey={getValueKey} type={typeEditField} onSubmit={sendValuesFields} />}

            <DndProvider backend={HTML5Backend}>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: 4, backgroundColor: '#FFFFFF' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                                <Button
                                    onClick={() => navigate(-1)}
                                    sx={{
                                        padding: 0,
                                        minWidth: 'auto',
                                        height: '56px',
                                        width: '35px',
                                        marginRight: 2,
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                >
                                    <KeyboardArrowLeftIcon
                                        sx={{
                                            backgroundColor: '#FFFFFF',
                                            border: '1px solid #D6D6D6',
                                            borderRadius: '12px',
                                            padding: '7px',
                                            height: '56px',
                                            width: '35px',
                                            color: 'black'
                                        }}
                                    />
                                </Button>

                                <TextField
                                    fullWidth
                                    label="Nome do onboarding"
                                    value={nameForm}
                                    variant="outlined"
                                    margin="none"
                                    onChange={handleNameForm}
                                    inputProps={{ maxLength: 90 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Limite máximo de 90 caracteres" placement="right">
                                                    <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    label="Título do onboarding"
                                    value={titleForm}
                                    variant="outlined"
                                    margin="none"
                                    onChange={handleTitleForm}
                                    inputProps={{ maxLength: 90 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Limite máximo de 90 caracteres" placement="right">
                                                    <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Accordion sx={{ backgroundColor: '#FFFFFF' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Anexos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {optionsAttachments.map((fields: any) => (
                                            <Grid item key={fields.id}>
                                                <DraggableTooltip
                                                    item={fields}
                                                    onDragEnd={() => handleDragEnd(fields)}
                                                    moveItem={moveItem}
                                                    handleEditFieldOpen={handleEditFieldOpen}
                                                    handleDeleteField={handleDeleteField}
                                                    choicesFields={choicesFields}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion sx={{ backgroundColor: '#FFFFFF' }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Perguntas</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {optionsFields.map((fields: any) => (
                                            <Grid item key={fields.id}>
                                                <DraggableTooltip
                                                    item={fields}
                                                    onDragEnd={() => handleDragEnd(fields)}
                                                    moveItem={moveItem}
                                                    handleEditFieldOpen={handleEditFieldOpen}
                                                    handleDeleteField={handleDeleteField}
                                                    choicesFields={choicesFields}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedAllRequiredQuestions}
                                            defaultChecked
                                            onChange={allRequiredQuestions}
                                        />
                                    }
                                    label='Marcar todas perguntas como NÃO obrigatórias?'
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={AddSession}
                                    sx={{
                                        height: "45px",
                                        color: '#FFFFFF',
                                        backgroundColor: '#9B4593',
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#9B4593',
                                        },
                                    }}
                                >
                                    Adicionar seção
                                </Button>
                            </Box>

                        </Paper>
                    </Grid>

                    {/* TELA 2*/}
                    <Grid item xs={12}>
                        <Paper
                            elevation={3}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: '500px',
                                backgroundColor: '#FFFFFF',
                                overflowY: 'auto'
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    sx={{
                                        padding: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 5,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >

                                    <DroppableArea onItemDropped={handleItemDropped}>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: "column",
                                                height: "100%",
                                                marginBottom: '15px',
                                                backgroundColor: '#FFFFFF'

                                            }}
                                        >
                                            {items.map((item, index) => (
                                                <DraggableItem
                                                    key={item.key}
                                                    item={item}
                                                    index={index}
                                                    moveItem={moveItem}
                                                />
                                            ))}
                                        </Box>
                                    </DroppableArea>
                                </Grid>
                            </Grid>

                            <Box sx={{
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '100%',
                                height: '100px',
                                marginTop: 2,
                                gap: 2,

                                backgroundColor: '#F9FAFB',
                            }}>

                                <Button
                                    sx={{
                                        height: "45px",
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '10px 32px',
                                        textTransform: 'none'
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={SavedOnboarding}
                                >
                                    Salvar Onboarding
                                </Button>

                                <Button
                                    sx={{
                                        height: "45px",
                                        color: '#FFFFFF',
                                        borderRadius: '8px',
                                        padding: '10px 32px',
                                        textTransform: 'none'
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={handleSendOnboadingOpen}
                                >
                                    Enviar Onboarding
                                </Button>
                            </Box>

                        </Paper>
                    </Grid>
                </Grid>
            </DndProvider >
        </ProtectRoutesOnboarding>
    );
}