import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useItemsStore } from "../../../contexts/zustand/onboarding"
import { SiMicrosoftexcel } from "react-icons/si"
import { BiBlock } from "react-icons/bi"
import { useDropzone } from 'react-dropzone'
import { DropArea } from "./dropZone"
import axios from 'axios';

import { v4 as uuidv4 } from "uuid";

import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    Tooltip,
    InputAdornment,
    Grid,
    MenuItem,
    Menu,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from "@mui/icons-material/Info";

import * as api from '../../../services/api';
import { APIOnboarding } from '../../../services/api';
import { useAuth } from "../../../hooks/useAuth";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from "react-router-dom"

type LayoutType = {
    responsible_user: string;
    user_name_responsible: string;
    name_model: string;
    title_onboarding: string;
    logo: string;
    primary_color: string;
    second_color: string;
    text_color: string;
    button_text_color: string;
    third_color: string;
    navbar_background_color: string;
    card_shadow_color: string;
    baseboard_color: string;
    baseboard_text_color: string;
    card_color: string;
    card_text_color: string;
    template_email: string;
}

type LayoutEmailType = {
    logo_email: string;
    title_color: string;
    title_background_color: string;
    text_button_color: string;
    button_background_color: string;
    text_footer_color: string;
    footer_background_color: string;
}

type ApiResponse = {
    content: {
        corporate_name: string;
        token_onboarding: string;
        layout_onboarding: {
            layout: LayoutType;
            layout_email: LayoutEmailType;
        };
    };
}

type SendOnboardingProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    nameModelOnboarding: string;
    titleOnboarding: string;
}

type QuestionsAndAttachments = {
    id: string;
    label: string;
    type: string;
    options?: string[];
    defaultValue?: string;
    value: string;
    position: number;
    key?: string;
    //reference_id?: string;
    required?: boolean;
    validations?: string[];
    can_gallery?: boolean;
    id_choice?: string;
    length_value?: number;
    response: boolean
}

type Signatory = {
    data: {
        label: string;
        type: string;
        value: string;
        validations: string[];
    }[];
    questions?: QuestionsAndAttachments[];
    attachments?: QuestionsAndAttachments[];
};

type RowTable = {
    id?: string;
    name_signatory: string;
    cpfCnpj: string;
    number_whats: string;
    email: string;
}

type MoreOptionsMenuProps = {
    id_signatory: string;
}

type JsonType = {
    shipping: {
        email: boolean;
        whatsapp: boolean;
    };
    name_model: string;
    layout: LayoutType;
    layout_email: LayoutEmailType;
    signatories?: Signatory[];
};

const jsonLayoutInitial: JsonType = {
    "shipping": {
        "email": false,
        "whatsapp": false
    },
    "name_model": "",
    "layout": {
        "responsible_user": "",
        "user_name_responsible": "",
        "name_model": "",
        "title_onboarding": "",
        "logo": "",
        "primary_color": "",
        "second_color": "",
        "text_color": "",
        "button_text_color": "",
        "third_color": "",
        "navbar_background_color": "",
        "card_shadow_color": "",
        "baseboard_color": "",
        "baseboard_text_color": "",
        "card_color": "",
        "card_text_color": "",
        "template_email": "",
    },
    "layout_email": {
        "logo_email": "",
        "title_color": "",
        "title_background_color": "",
        "text_button_color": "",
        "button_background_color": "",
        "text_footer_color": "",
        "footer_background_color": "",
    }
};

let json: JsonType = {
    "shipping": {
        "email": false,
        "whatsapp": false
    },
    "name_model": "",
    "layout": {
        "user_name_responsible": "",
        "responsible_user": "",
        "name_model": "",
        "title_onboarding": "",
        "logo": "",
        "primary_color": "",
        "second_color": "",
        "text_color": "",
        "button_text_color": "",
        "third_color": "",
        "navbar_background_color": "",
        "card_shadow_color": "",
        "baseboard_color": "",
        "baseboard_text_color": "",
        "card_color": "",
        "card_text_color": "",
        "template_email": "",
    },
    "layout_email": {
        "logo_email": "",
        "title_color": "",
        "title_background_color": "",
        "text_button_color": "",
        "button_background_color": "",
        "text_footer_color": "",
        "footer_background_color": "",
    }
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    maxHeight: '90vh',
    overflowY: 'auto'
};

const columns = ['Nome', 'CPF/CNPJ', 'Número', 'Email', 'Ações'];

export default function SendOnboarding({ open, setOpen, nameModelOnboarding, titleOnboarding }: SendOnboardingProps) {

    const { user } = useAuth()
    const modalRef = useRef<HTMLDivElement>(null);

    const { items, fileProcessing } = useItemsStore()

    const navigate = useNavigate()

    const [tokenOboarding, setTokenOnboarding] = useState<string>('')
    const [layoutOnboarding, setLayoutOnboarding] = useState<LayoutType | null>(null);
    const [layoutEmail, setLayoutEmail] = useState<LayoutEmailType | null>(null);

    const [nameCorporate, setNameCorporate] = useState<string>('')

    const [name, setName] = useState<string>('');
    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [numberWhats, setNumberWhats] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [errorName, setErrorName] = useState<string>('');
    const [errorCpfCnpj, setErrorCpfCnpj] = useState<string>('');
    const [errorNumber, setErrorNumber] = useState<string>('');
    const [errorEmail, setErrorEmail] = useState<boolean>(false);

    const [editingData, setEditingData] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [sendFile, setSendFile] = useState<boolean>(false)
    const [data, setData] = useState<RowTable[]>([]);

    const [isDropped, setIsDropped] = useState<boolean>(false)
    const [click, setClick] = useState<boolean>(false)

    const [sendOnboardingClick, setSendOnboardingClick] = useState<boolean>(false)

    useEffect(() => {
        const companyId = user?.corporate_id!;
        const fetchData = async () => {
            try {
                const response = await api.get(`company/retrieve/${companyId}/`) as ApiResponse;
                setNameCorporate(response.content.corporate_name)
                setTokenOnboarding(response.content.token_onboarding);
                setLayoutOnboarding(response.content.layout_onboarding.layout);
                setLayoutEmail(response.content.layout_onboarding.layout_email)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [user?.corporate_id]);

    const resetStates = () => {
        setName('')
        setErrorName('')
        setCpfCnpj('')
        setErrorCpfCnpj('')
        setNumberWhats('')
        setErrorNumber('')
        setEmail('')
        setErrorEmail(false)
        setEditingData(null)
        setData([])
        setOpen(false)
        setFile(null)
        setClick(false)
        setIsDropped(false)
        setSendOnboardingClick(false)
        json = jsonLayoutInitial
        json.signatories = []
    }

    const handleClose = () => {
        resetStates()
        setSendFile(false)
    }

    const DropZoneFile = () => {

        const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
            accept: {
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/vnd.ms-excel': ['.xls']
            },
            maxFiles: 1,
            multiple: false,
            noKeyboard: click,
            onDropAccepted: acceptedFile => {
                setFile(acceptedFile[0])
                setIsDropped(true)
                setClick(true)
            },
            onDropRejected: () => {
                toast.warning('Arquivo inválido', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        })

        return (
            <DropArea
                isDropped={isDropped}
                isDragActive={isDragActive}
                isDragReject={isDragReject}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {
                    isDragActive
                        ? isDragReject
                            ? <>
                                <BiBlock /> &nbsp;
                                <p>Apenas arquivos com extensão <strong>xlsx</strong> ou <strong>xls</strong> são permitidos.</p>
                            </>
                            : <>
                                <SiMicrosoftexcel /> &nbsp;
                                <p><strong>Arquivo permitido, pode soltar</strong></p>
                            </>
                        : isDropped
                            ? <>
                                <SiMicrosoftexcel /> &nbsp;
                                <p>
                                    {/* @ts-ignore */}
                                    Arquivo <strong>{file.name}</strong> adicionado
                                </p>
                            </>
                            : <>
                                <SiMicrosoftexcel /> &nbsp;
                                <p>
                                    Adicione um arquivo <strong>xlsx</strong> ou <strong>xls</strong>
                                </p>
                            </>
                }
            </DropArea>
        )
    }

    const ModalSendFile = () => {
        return (
            <>
                <Box mb={2}>
                    <Typography variant="body1">
                        Sua planilha precisa conter <strong>exatamente</strong> as colunas abaixo com os <strong>exatos nomes</strong>, respeitando inclusive caracteres minúsculos.
                    </Typography>
                </Box>

                <Grid container justifyContent="center">
                    <Grid item>
                        <Box
                            border={1}
                            borderColor="black"
                            p={1}
                            textAlign="center"
                            width="100px"
                        >
                            nome
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            borderTop={1}
                            borderBottom={1}
                            borderRight={1}
                            borderColor="black"
                            p={1}
                            textAlign="center"
                            width="100px"
                        >
                            cpf/cnpj
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            borderTop={1}
                            borderBottom={1}
                            borderRight={1}
                            borderColor="black"
                            p={1}
                            textAlign="center"
                            width="120px"
                        >
                            whatsapp
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            borderTop={1}
                            borderBottom={1}
                            borderRight={1}
                            borderColor="black"
                            p={1}
                            textAlign="center"
                            width="120px"
                        >
                            email
                        </Box>
                    </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                    <DropZoneFile />
                </Grid>

                <Box display="flex" justifyContent="center" mt={2} gap={2}>
                    <Button
                        sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            '&:hover': {
                                backgroundColor: '#F5F5F5',
                            },
                            borderRadius: '8px',
                            border: '1px solid #D1D5DB',
                            width: { xs: '100%', sm: '150px' },
                            marginBottom: { xs: '10px', sm: '0' }
                        }}
                        onClick={() => { setSendFile(false); setFile(null); setIsDropped(false) }}
                    >
                        Voltar
                    </Button>

                    <Button
                        sx={{
                            backgroundColor: '#FFA890',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#FFA890',
                            },
                            borderRadius: '8px',
                            width: { xs: '100%', sm: '150px' },
                        }}
                        disabled={file === null || sendOnboardingClick}
                        onClick={sendOnboarding}
                    >
                        {sendOnboardingClick ? 'Enviando...' : 'Enviar'}
                    </Button>
                </Box>
            </>
        );
    }

    function clearDataSignatory(e: React.MouseEvent<HTMLLabelElement>) {
        setName('')
        setErrorName('')
        setCpfCnpj('')
        setErrorCpfCnpj('')
        setNumberWhats('')
        setErrorNumber('')
        setEmail('')
        setErrorEmail(false)
        setEditingData(null)
        setData([])
        setSendFile(true)
    }

    const handleCheckTypeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/;
        const verifyTypeName = regex.test(value)

        if (!verifyTypeName) {
            setErrorName('Digite somente letras!!')
            return
        } else {
            setErrorName('')
        }

        setName(value);
    };

    const handleCheckCpfCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const regex = /^\d*$/;
        const verifyCpfCnpj = regex.test(value)

        if (!verifyCpfCnpj) {
            setErrorCpfCnpj('Digite somente números');
            return false;
        }

        setCpfCnpj(value);
        return true;
    };

    const handlecheckPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const regex = /^\d*$/;
        const verifyTypeNumber = regex.test(value)

        if (!verifyTypeNumber) {
            setErrorNumber('Digite somente números')
            return false
        }

        if (value.length > 11) {
            setErrorNumber('O número não pode ter mais de 11 dígitos');
            return false;
        }

        setErrorNumber('')
        setNumberWhats(value);
    };

    const checkTypeEmail = (email: string) => {

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const verifyTypeEmail = regex.test(email);

        if (!verifyTypeEmail) return false

        return true

    };

    const addSignatory = (e: React.MouseEvent<HTMLButtonElement>) => {

        if (!name) {
            setErrorName('Adicione um nome e número/email');
            return;
        }

        if (cpfCnpj && cpfCnpj.length < 11) {
            setErrorCpfCnpj('CPF deve ter 11 dígitos e CNPJ deve ter 14 dígitos');
            return false;
        }


        if (!numberWhats && !email) {
            toast.error('Adicione um número/email');
            return;
        }

        if (email && !checkTypeEmail(email)) {
            toast.error('Digite um email válido!!');
            setErrorEmail(true);
            return;
        }

        const newData: RowTable = { id: uuidv4(), name_signatory: name, cpfCnpj: cpfCnpj, number_whats: numberWhats, email };

        if (editingData) {
            const updatedData = data.map(item =>
                item.id === editingData ? newData : item
            );
            setData(updatedData);
            setEditingData(null);
        } else {
            setData([...data, newData]);
        }

        setName('');
        setErrorName('');
        setCpfCnpj('');
        setErrorCpfCnpj('');
        setNumberWhats('');
        setErrorNumber('');
        setEmail('');
        setErrorEmail(false);
    }

    const MoreOptionsMenu = ({ id_signatory }: MoreOptionsMenuProps) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const editDataSignatory = () => {
            const signatory = data.find(item => item.id === id_signatory)!;

            setName(signatory.name_signatory)
            setCpfCnpj(signatory.cpfCnpj)
            setNumberWhats(signatory.number_whats)
            setEmail(signatory.email)
            setEditingData(signatory.id!);

            if (modalRef.current) {
                modalRef.current.scrollTo(0, 0);
            }
        }

        const removeDataSignatory = () => {
            const filteredData = data.filter(item => item.id !== id_signatory);

            setData(filteredData);

            if (filteredData.length === 0) {
                setName('');
                setCpfCnpj('');
                setNumberWhats('');
                setEmail('');
                setEditingData(null);
            }
        };

        const menuItems = () => {
            return [
                <MenuItem key="editar" onClick={() => { editDataSignatory(); handleClose(); }}>
                    <Typography sx={{ cursor: 'pointer' }}>Editar</Typography>
                </MenuItem>,
                <MenuItem key="excluir" onClick={() => { removeDataSignatory(); handleClose(); }}>
                    <Typography sx={{ cursor: 'pointer', color: '#FFA890' }}>Excluir</Typography>
                </MenuItem>
            ];
        };

        return (
            <Grid>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {menuItems()}
                </Menu>
            </Grid>
        );
    };

    const createSignatory = () => {

        if (data.length === 0 && (name && (numberWhats || email))) {
            const newSignatory: Signatory = {
                data: [
                    { label: "nome", type: "1", value: name, validations: [] },
                    { label: "cpf_cnpj", type: "1", value: cpfCnpj, validations: [] },
                    { label: "whatsapp", type: "2", value: numberWhats, validations: [] },
                    { label: "email", type: "1", value: email, validations: [] },
                    { label: "nome_empresa", type: "1", value: nameCorporate, validations: [] },
                ],
            };

            json.shipping.email = json.shipping.email || !!email;
            json.shipping.whatsapp = json.shipping.whatsapp || !!numberWhats;
            json.signatories = json.signatories || [];
            json.signatories.push(newSignatory);
        }

        data.forEach(({ name_signatory, cpfCnpj, number_whats, email }: RowTable) => {

            const newSignatory: Signatory = {
                data: [
                    { label: "nome", type: "1", value: name_signatory, validations: [] },
                    { label: "cpf_cnpj", type: "1", value: cpfCnpj, validations: [] },
                    { label: "whatsapp", type: "2", value: number_whats, validations: [] },
                    { label: "email", type: "2", value: email, validations: [] },
                    { label: "nome_empresa", type: "1", value: nameCorporate, validations: [] },
                ],
            };

            json.shipping.email = json.shipping.email || !!email;
            json.shipping.whatsapp = json.shipping.whatsapp || !!number_whats;
            json.signatories = json.signatories || [];
            json.signatories.push(newSignatory);
        });

        return true

    };

    const createQuestionsAndAttachments = (questions: QuestionsAndAttachments[]) => {

        let position_question = 0;
        const usedPositions = new Set<number>();

        while (usedPositions.has(position_question)) {
            position_question++;
        }

        questions.forEach(question => {
            const newElement: QuestionsAndAttachments = {
                id: question.key!,
                position: position_question,
                label: question.label,
                type: question.type,
                options: question.options || [],
                value: question.defaultValue || "",
                required: question.required || false,
                //reference_id: question.reference_id,
                validations: [],
                length_value: question.length_value,
                response: false
            };

            usedPositions.add(position_question);
            position_question++;

            if (question.id_choice === '2') {
                newElement.can_gallery = true;
            }

            if (data.length === 0 && file) {
                if (!json.signatories || json.signatories.length === 0) {
                    json.signatories = [{
                        data: [],
                        questions: [],
                        attachments: []
                    }];
                }
                const key = ['1', '2'].includes(question.id_choice!) ? 'attachments' : 'questions';

                json.signatories[0][key] = json.signatories[0][key] || [];
                json.signatories[0][key]!.push(newElement);

                return
            }

            json.signatories = json.signatories?.map(signatory => {
                const key = ['1', '2'].includes(question.id_choice!) ? 'attachments' : 'questions';
                signatory[key] = signatory[key] || [];
                (signatory[key] ??= []).push(newElement);
                return signatory;
            });
        });
    };

    const sendOnboarding = async () => {
        setSendOnboardingClick(true)

        toast.loading('Aguarde...Estamos preparando para o envio!!')

        const responseCreateSignatory = createSignatory()

        if (!responseCreateSignatory) return

        createQuestionsAndAttachments(items as QuestionsAndAttachments[])

        json.layout = layoutOnboarding as LayoutType
        json.layout.title_onboarding = titleOnboarding
        json.layout.name_model = nameModelOnboarding;
        json.layout.responsible_user = user?.email!
        json.layout.user_name_responsible = user?.name!

        json.layout_email = layoutEmail!

        if (file) {

            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('json', JSON.stringify(json));

                const response = await APIOnboarding.post(`onboarding/create/?token=${tokenOboarding}&name_corporate=${nameCorporate}`, formData,
                    {
                        responseType: 'blob'
                    });

                if (response.status === 201) {
                    toast.dismiss()
                    toast.success('Onboarding enviado com Sucesso!!', { autoClose: 2000 })
                    toast.success('Redirecionando para a página principal!!', { autoClose: 2000 })
                    setTimeout(() => {
                        toast.dismiss();
                        window.location.reload();
                    }, 2000)
                }

            } catch (error) {
                if (axios.isAxiosError(error)) {
                    toast.dismiss()
                    if (error.response && error.response.status === 422) {
                        toast.error('Nomes das colunas erradas!', { autoClose: 2000 });
                    } else {
                        toast.error('Ocorreu um erro ao tentar enviar onboarding, tente novamente mais tarde', { autoClose: 2000 });
                    }
                } else {
                    console.error('Erro desconhecido:', error);
                }
            }

            resetStates()
            return;
        }

        try {
            const response = await APIOnboarding.post(`onboarding/create/?token=${tokenOboarding}`, json);

            if (response.status === 201) {

                toast.dismiss();
                toast.success('Onboarding enviado com Sucesso!!')
                toast.success('Redirecionando para a página principal!!')
                setTimeout(() => {
                    toast.dismiss();
                    navigate('/onboarding');
                }, 3000)
            }
        } catch (error) {
            toast.dismiss()
            toast.error('Ops... Tente novamente mais tarde!!')
        }

        resetStates()

    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowY: data.length > 1 ? 'auto' : 'hidden' }}
        >
            <Box ref={modalRef} sx={modalStyle}>

                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                        fontSize: '24px',
                        textAlign: 'center',
                        color: '#808080',
                        marginBottom: '10px',
                        wordBreak: 'break-all'
                    }}>
                    {titleOnboarding}
                </Typography>

                <Typography
                    sx={{
                        fontSize: '24px',
                        color: '#FFA089',
                        marginBottom: '10px'
                    }}>
                    {sendFile ? 'Envio em lote com planilha' : 'Dados para envio do formulário'}
                </Typography>

                {sendFile ? (
                    <>
                        <ModalSendFile />
                    </>
                ) : (
                    <>
                        <Typography
                            sx={{
                                color: 'text.secondary',
                                marginBottom: '10px'
                            }}>
                            Preencha com os dados de quem receberá o formulário
                        </Typography>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                                <Box sx={{ width: '100%', marginBottom: '10px' }}>
                                    <TextField
                                        label="Nome"
                                        value={name}
                                        onChange={handleCheckTypeName}
                                        sx={{
                                            borderColor: '#9454A4', borderRadius: '5px', '&:hover': { border: 'none' }
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        inputProps={{ maxLength: 40 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={
                                                            <Box>
                                                                Digite somente letras!!<br />
                                                                Limite máximo de 40 caracteres
                                                            </Box>
                                                        }
                                                        placement="right"
                                                    >
                                                        <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!!errorName}
                                        helperText={errorName}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Box sx={{ width: '100%', marginBottom: '10px' }}>
                                    <TextField
                                        label="CPF/CNPJ"
                                        value={cpfCnpj}
                                        onChange={handleCheckCpfCnpj}
                                        sx={{
                                            borderColor: '#9454A4', borderRadius: '5px', '&:hover': { border: 'none' }
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        inputProps={{ maxLength: 14 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={
                                                            <Box>
                                                                Digite somente números!!<br />
                                                                Limite máximo de 14 caracteres
                                                            </Box>
                                                        }
                                                        placement="right"
                                                    >
                                                        <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!!errorCpfCnpj}
                                        helperText={errorCpfCnpj}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Box sx={{ width: '100%', marginBottom: '10px' }}>
                                    <TextField
                                        label="Número do WhatsApp"
                                        value={numberWhats}
                                        placeholder="21999999999"
                                        onChange={handlecheckPhone}
                                        sx={{
                                            borderColor: '#1e1620', borderRadius: '5px', '&:hover': { border: 'none' }
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        inputProps={{ maxLength: 15 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={
                                                            <Box>
                                                                Digite somente números!!<br />
                                                                Limite máximo de 15 caracteres
                                                            </Box>
                                                        }
                                                        placement="right"
                                                    >
                                                        <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!!errorNumber}
                                        helperText={errorNumber}
                                    />
                                </Box>

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Box sx={{ width: '100%', marginBottom: '10px' }}>
                                    <TextField
                                        label="Email"
                                        value={email}
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        sx={{
                                            borderColor: '#9454A4', borderRadius: '5px', '&:hover': { border: 'none' }
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        inputProps={{ maxLength: 40 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={
                                                            <Box>
                                                                Digite um email válido!!<br />
                                                                Limite máximo de 40 caracteres
                                                            </Box>
                                                        }
                                                        placement="right"
                                                    >
                                                        <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={errorEmail}
                                    />
                                </Box>
                            </Grid>

                        </Grid>


                        {data.length > 0 && (
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((item: string, index: number) => (
                                                    <TableCell
                                                        key={index}
                                                        align='left'
                                                        sx={{
                                                            color: '#432F48',
                                                            backgroundColor: '#FAFAFA',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}
                                                    >
                                                        {item}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((row, rowIndex) => (
                                                <TableRow
                                                    key={rowIndex}
                                                    sx={{
                                                        backgroundColor: '#FAFAFA'
                                                    }}
                                                >
                                                    <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.name_signatory}</TableCell>
                                                    <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.cpfCnpj}</TableCell>
                                                    <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.number_whats}</TableCell>
                                                    <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.email}</TableCell>
                                                    <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>
                                                        <MoreOptionsMenu id_signatory={row.id!} />
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        )}

                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', marginTop: '20px', gap: '10px', height: '50px' }}>

                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-end', gap: '10px' }}>
                                <Button
                                    sx={{
                                        display: 'flex',
                                        justifyContent: { xs: 'flex', sm: '150px' },
                                        backgroundColor: '#9454A4',
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            color: '#FFFFFF',
                                            backgroundColor: '#9454A4',
                                        },
                                        borderRadius: '8px',
                                        width: { xs: '100%', sm: '150px' },
                                        height: 'auto',
                                        marginBottom: { xs: '10px', sm: '0' }
                                    }}
                                    onClick={addSignatory}
                                >
                                    {editingData === null ? 'Adicionar Destinatário' : 'Salvar'}
                                </Button>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Tooltip title={fileProcessing ? "Aguarde o término do envio em lote" : ""} placement="top-start" arrow>
                                        <span>
                                            <Button
                                                component="label"
                                                endIcon={<CloudUploadIcon />}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    height: '50px',
                                                    color: '#FFA089',
                                                    backgroundColor: '#FFFFFF',
                                                    textTransform: 'none',
                                                    border: '1px solid #FFA089',
                                                    borderRadius: '8px',
                                                    '&:hover': {
                                                        backgroundColor: '#FFFFFF',
                                                        color: '#FFA089',
                                                        border: '1px solid #FFA089',
                                                    },
                                                }}
                                                onClick={clearDataSignatory}
                                                disabled={fileProcessing}
                                            >
                                                Adicionar Planilha
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Box>

                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-end', gap: '10px' }}>
                                <Button
                                    sx={{
                                        backgroundColor: '#FFFFFF',
                                        color: '#000000',
                                        '&:hover': {
                                            backgroundColor: '#F5F5F5',
                                        },
                                        borderRadius: '8px',
                                        border: '1px solid #D1D5DB',
                                        width: { xs: '100%', sm: '150px' },
                                        marginBottom: { xs: '10px', sm: '0' }
                                    }}
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    sx={{
                                        backgroundColor: '#FFA890',
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            color: 'white',
                                            backgroundColor: '#FFA890',
                                        },
                                        borderRadius: '8px',
                                        width: { xs: '100%', sm: '150px' },
                                    }}
                                    disabled={file === null && (data.length === 0 && (name === '' || (numberWhats === '' && email === ''))) || sendOnboardingClick}
                                    onClick={sendOnboarding}
                                >
                                    {sendOnboardingClick ? 'Enviando...' : 'Enviar'}
                                </Button>
                            </Box>

                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

