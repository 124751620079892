import { create } from 'zustand';
import { v4 as uuidv4 } from "uuid";

export type DraggableItem = {
    id?: string;
    key?: string;
    id_choice?: string;
    type?: string;
    label?: string;
    required?: boolean;
    name?: string,
    error?: string,
    options?: string[],
    //reference_id?: string,
    length_label?: number,
    length_value?: number,
    onDragEnd?: (item: DraggableItem) => void
}

type FormState = {
    fileProcessing: boolean,
    items: DraggableItem[],
    label: { [key: string]: string };
    optionsValues: { [key: string]: string[] };
    valueRequired: { [key: string]: boolean };
    addItems: (item: DraggableItem) => void;
    removeItems: (key: string) => void;
    updateItems: (items: DraggableItem[]) => void;
    setLabel: (key: string, value: string) => void;
    setOptionsValues: (key: string, options: string[]) => void;
    setValueRequired: (key: string, value: boolean) => void;
    clearFormValues: () => void;
    statuFileProcessing: (newState: boolean) => void;
}

export const useItemsStore = create<FormState>((set) => ({
    fileProcessing: false,
    items: [],
    label: {},
    optionsValues: {},
    valueRequired: {},
    addItems: (item) => set((state) => ({
        items: [...state.items, { 
            id: item.id, 
            key: uuidv4(), 
            //key: item.key || uuidv4(), 
            //reference_id: item.reference_id,
            id_choice: item.id_choice,
            type: item.type,
            label: item.label, 
            required: item.required || false, 
            name: item.name, 
            error: item.error, 
            options: item.options || [],
            length_label: item.length_label,
            length_value: item.length_value
        }]
    })),
    updateItems: (updatedItems) => set(() => ({
        items: updatedItems
    })),
    removeItems: (key) => set((state) => ({ 
        items: state.items.filter(item => item.key !== key)
    })),
    setLabel: (key, value) => set((state) => ({
        label: { ...state.label, [key]: value }
    })),
    setOptionsValues: (key, options) => set((state) => ({
        optionsValues: { ...state.optionsValues, [key]: options }
    })),
    setValueRequired: (key, value) => set((state) => ({
        valueRequired: { ...state.valueRequired, [key]: value }
    })),
    clearFormValues: () => set(() => ({
        items: [],
        label: {},
        optionsValues: {},
        valueRequired: {}
    })),
    statuFileProcessing: (newState) => set(() => ({
        fileProcessing: newState
    }))
}));
