import * as yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios'
import Grid from '@mui/material/Grid'
import { toast } from 'react-toastify'
import Button from "@mui/material/Button"
import * as api from '../../../services/api'
import { APIOnboarding } from '../../../services/api'
import { useParams } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import TextField from '@mui/material/TextField'
import { useAuth } from "../../../hooks/useAuth"
import { useCore } from '../../../hooks/useCore'
import { cookies } from '../../../hooks/useCookies'
import { LightMode } from '../../../styles/lightMode'
import BusinessIcon from '@mui/icons-material/Business'
import { formatMoney } from '../../../utils/format-money'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { LoadingPage } from '../../../components/LoadingPage'
import { MaskCEP, MaskCNPJ, MaskCPF } from '../../../utils/mask'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { PriceListProps, PriceProductsProps, RetrieveCompanyProps } from '../../../types/company'
import { Box, Alert, Avatar, Chip, Divider, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Switch } from '@mui/material'
import { BsUiChecks } from 'react-icons/bs'

type ResponseDataViaCepProps = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

const validationSchema = yup.object({
  responsible_email: yup
    .string()
    .email('Informe um email válido')
    .required('O campo Email do responsável deve ser preenchido'),
})

export default function CreateOrUpdateCompany(): JSX.Element {
  let { id } = useParams()
  const { setTitleBar, setPathTitleBar } = useCore()

  const { handleChange, handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      corporate_name: '',
      state_registration: '',
      cnpj: '',
      fantasy_name: '',
      cep: '',
      street: '',
      number_address: '',
      complement: '',
      district: '',
      city: '',
      uf: '',
      responsible_email: '',
      responsible_name: '',
      responsible_cpf: '',
      responsible_birth: '',
      domain: '',
      site: '',
      token_onboarding: '',
      using_onboarding: false,
      layout_onboarding: {
        layout: {
          logo: '',
          title_onboarding_color: '',
          title_section: '',
          card_section: '',
          primary_color: '',
          second_color: '',
          text_color: '',
          button_text_color: '',
          third_color: '',
          navbar_background_color: '',
          card_shadow_color: '',
          baseboard_color: '',
          baseboard_text_color: '',
          card_color: '',
          card_text_color: ''
        },
        layout_email: {
          logo_email: '',
          title_color: '',
          title_background_color: '',
          text_button_color: '',
          button_background_color: '',
          text_footer_color: '',
          footer_background_color: '',
        }
      },
      balance: ''
    },
    validationSchema: validationSchema,
    onSubmit: createOrUpdateCompany,
  })

  const [pricesList, setPricesList] = useState<PriceListProps | null>(null)

  const [editing, setEditing] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [creating, setCreating] = useState<boolean>(false)
  const [checkMatch, setCheckMatch] = useState<boolean>(false)
  const [isTokenOnboardingSet, setIsTokenOnboardingSet] = useState<boolean>(false);

  const getCompany = useCallback(async () => {
    setLoading(true)
    const response = await api.get(`company/retrieve/${id}/`) as RetrieveCompanyProps
    const layout = response.content.layout_onboarding?.layout ?? '';
    const layout_email = response.content.layout_onboarding?.layout_email ?? '';
    setFieldValue('corporate_name', response.content.corporate_name)
    setFieldValue('state_registration', response.content.state_registration ?? '')
    setFieldValue('cnpj', response.content.cnpj)
    setFieldValue('fantasy_name', response.content.fantasy_name)
    setFieldValue('cep', response.content.cep)
    setFieldValue('street', response.content.street)
    setFieldValue('number_address', response.content.number_address)
    setFieldValue('complement', response.content.complement ?? '')
    setFieldValue('district', response.content.district)
    setFieldValue('city', response.content.city)
    setFieldValue('uf', response.content.uf)
    setFieldValue('responsible_email', response.content.responsible_email)
    setFieldValue('responsible_name', response.content.responsible_name)
    setFieldValue('responsible_cpf', response.content.responsible_cpf)
    setFieldValue('responsible_birth', response.content.responsible_birth)
    setFieldValue('domain', response.content.domain ?? '')
    setFieldValue('site', response.content.site ?? '')
    setFieldValue('token_onboarding', response.content.token_onboarding ?? '')
    setFieldValue('using_onboarding', response.content.using_onboarding ?? false)
    setIsTokenOnboardingSet(!!response.content.token_onboarding);
    setFieldValue('layout_onboarding.layout', layout)
    setFieldValue('layout_onboarding.layout_email', layout_email)
    setFieldValue('balance', response.content.balance ?? '')
    setFieldValue('created_at', response.content.created_at)
    setFieldValue('updated_at', response.content.updated_at)
    setFieldValue('is_active', response.content.is_active)
    setPricesList(response.content.prices_list[0])
    setCheckMatch(response.content.checkmatch)
    setLoading(false)
  }, [id, setFieldValue])

  async function validateCEP(cep: string) {
    function errorPromise() {
      setFieldValue('street', '')
      setFieldValue('district', '')
      setFieldValue('city', '')
      setFieldValue('uf', '')
      toast.error("CEP informado inválido!", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    const promise = new Promise((resolve, reject) => {
      fetch(`https://viacep.com.br/ws/${cep}/json/`).then((response) => {
        resolve(response.json())
      }).catch((error) => {
        errorPromise()
      })
    })

    await promise.then(response => {
      const responseData = response as ResponseDataViaCepProps
      if (JSON.stringify(responseData).indexOf('erro') > -1) {
        errorPromise()
        return
      }
      setFieldValue('street', responseData.logradouro)
      setFieldValue('district', responseData.bairro)
      setFieldValue('city', responseData.localidade)
      setFieldValue('uf', responseData.uf)
    })
  }

  async function createOrUpdateCompany() {
    setCreating(true)
    let data: any = { ...values }
    data.balance = formatMoney(values.balance).toString()
    data.checkmatch = checkMatch

    if (!isTokenOnboardingSet) data.layout_onboarding = null

    if (id) {
      toast.loading("Atualizando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      var resUpdated = await api.put(`company/update/${id}/`, data) as { [key: string]: any }

      toast.dismiss()
      setCreating(false)

      if (resUpdated.status === 200) {
        toast.success("Dados atualizados com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } else {
      toast.loading("Criando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      var resCreated = await api.post('company/create/', data) as { [key: string]: any }

      toast.dismiss()

      if (resCreated.status === 201) {
        cookies.set('@CheckView:idCompanyCreatedNow', resCreated.data.content.id)
        return window.location.replace(`/company/${resCreated.data.content.id}`)
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
        setCreating(false)
      }
    }
  }

  const setAvailable = (index: number) => () => {
    setEditing(true)
    const newPricesList = pricesList as PriceListProps
    newPricesList!.price_products[index].available = !newPricesList!.price_products[index].available
    setPricesList(pricesList => ({
      ...pricesList,
      ...newPricesList
    }))
  }

  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setEditing(true)
    const newValue = e.target.value
    const newPricesList = pricesList as PriceListProps

    if (e.target.name === 'price') {
      newPricesList!.price_products[index].price = newValue
    } else {
      newPricesList!.price_products[index].match_price = newValue
    }

    setPricesList(pricesList => ({
      ...pricesList,
      ...newPricesList
    }))
  }

  async function savePricesList() {
    setEditing(false)
    toast.loading("Atualizando lista de preços...", {
      position: toast.POSITION.TOP_RIGHT,
    })

    let id_price_list = pricesList?.id
    let data: PriceProductsProps[] = JSON.parse(JSON.stringify(pricesList?.price_products!))

    data!.forEach((item) => {
      item.price = formatMoney(item.price.toString())
      item.match_price = formatMoney(item.match_price.toString())
    })

    var resUpdatedPriceList = await api.put(
      `company/update/price-list/${id_price_list}/`,
      { "price_products": data }
    ) as { [key: string]: any }

    toast.dismiss()

    if (resUpdatedPriceList.status === 200) {
      toast.success("Lista de preço atualizada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
        position: toast.POSITION.TOP_RIGHT
      })
      setEditing(true)
    }
  }

  const createTokenOnboarding = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const generateRandomPassword = () => {

      const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const digits = '0123456789';
      const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';
      const allCharacters = lowerCaseLetters + upperCaseLetters + digits + specialCharacters;

      let password = [
        lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)],
        upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)],
        digits[Math.floor(Math.random() * digits.length)],
        specialCharacters[Math.floor(Math.random() * specialCharacters.length)]
      ];

      for (let i = 4; i < 12; i++) {
        password.push(allCharacters[Math.floor(Math.random() * allCharacters.length)]);
      }

      password = password.sort(() => Math.random() - 0.5);

      return password.join('');
    }

    const email = values.responsible_email
    const password = generateRandomPassword()
    //const staff = user?.profile === '3' ? true : false

    const formData = {
      "email": email,
      "password": password,
      "is_staff": false,
    }

    try {

      const createUserResponse = await APIOnboarding.post('user/create/', formData);

      if (createUserResponse.status === 201) {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        const tokenResponse = await APIOnboarding.post('user/api/token/', formData);

        if (tokenResponse.status === 200) {

          let data = {
            ...values,
            using_onboarding: true,
            layout_onboarding: {
              ...values.layout_onboarding,
              layout: {
                logo: 'https://storage-checkview.s3.amazonaws.com/images/logo_oficial_CheckView_white.png',
                title_onboarding_color: '#6B7280',
                title_section: '#6B7280',
                card_section: '#fafafa',
                primary_color: '#6B7280',
                second_color: '#FF0000',
                text_color: '#6B7280',
                button_text_color: '#008000',
                third_color: '#008000',
                navbar_background_color: '#FFA890',
                card_shadow_color: '#E8EAEC',
                baseboard_color: '#6B7280',
                baseboard_text_color: '#E8EAEC',
                card_color: '#fafafa',
                card_text_color: '#6B7280',
              },
              layout_email: {
                  logo_email: '',
                  title_color: '#FFFFFF',
                  title_background_color: '#FFA890',
                  text_button_color: '#FFFFFF',
                  button_background_color: '#9756A1',
                  text_footer_color: '#888888',
                  footer_background_color: '#F4F6F8',
              }
            },
            token_onboarding: tokenResponse.data.token,
          };

          const resUpdated = await api.put(`company/update/${id}/`, data) as { [key: string]: any }
          if (resUpdated.status === 200) {
            toast.success('Token Criado com sucesso!!')
            window.location.reload();
          }
        }
      }

    } catch (error) {
      toast.error('Ops... Tivemos um problema, ao criar token para onboarding');
    }
  }

  useEffect(() => {
    if (id && id === cookies.get('@CheckView:idCompanyCreatedNow')) {
      toast.success("Empresa cadastrada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT
      })
      cookies.remove('@CheckView:idCompanyCreatedNow')
    }

    if (id) getCompany().catch(console.error)

  }, [getCompany, id])

  useEffect(() => {
    if (id) {
      setTitleBar(`Empresa - ${values.fantasy_name}`)
    } else {
      setTitleBar('Nova empresa')
    }
  }, [setTitleBar, values.fantasy_name, id])

  useLayoutEffect(() => {
    setPathTitleBar(undefined)
  }, [setPathTitleBar])


  return (
    <>
      <ToastContainer />
      {
        !loading
          ? <>
            <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <Grid
                container
                spacing={2}
                alignItems="center"

              >
                <Grid item md={12} xs={12}>
                  <h3>Dados cadastrais</h3>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="corporate_name"
                    name="corporate_name"
                    label="Razão social"
                    value={values.corporate_name}
                    onChange={handleChange}
                    error={touched.corporate_name && Boolean(errors.corporate_name)}
                    helperText={touched.corporate_name && errors.corporate_name?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    id="state_registration"
                    name="state_registration"
                    label="Registro estadual"
                    value={values.state_registration}
                    onChange={handleChange}
                    error={touched.state_registration && Boolean(errors.state_registration)}
                    helperText={touched.state_registration && errors.state_registration?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    value={values.cnpj}
                    onChange={handleChange}
                    InputProps={{ inputComponent: MaskCNPJ as any }}
                    error={touched.cnpj && Boolean(errors.cnpj)}
                    helperText={touched.cnpj && errors.cnpj?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="fantasy_name"
                    name="fantasy_name"
                    label="Nome fantasia"
                    value={values.fantasy_name}
                    onChange={handleChange}
                    error={touched.fantasy_name && Boolean(errors.fantasy_name)}
                    helperText={touched.fantasy_name && errors.fantasy_name?.toString()}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="cep"
                    name="cep"
                    label="CEP"
                    value={values.cep}
                    onChange={handleChange}
                    InputProps={{ inputComponent: MaskCEP as any }}
                    onBlur={e => {
                      validateCEP(e.currentTarget.value)
                    }}
                    error={touched.cep && Boolean(errors.cep)}
                    helperText={touched.cep && errors.cep?.toString()}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    disabled={true}
                    id="street"
                    name="street"
                    label="Logradouro"
                    value={values.street}
                    onChange={handleChange}
                    error={touched.street && Boolean(errors.street)}
                    helperText={touched.street && errors.street?.toString()}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    type="number"
                    id="number_address"
                    name="number_address"
                    label="Número"
                    value={values.number_address}
                    onChange={handleChange}
                    error={touched.number_address && Boolean(errors.number_address)}
                    helperText={touched.number_address && errors.number_address?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    id="complement"
                    name="complement"
                    label="Complemento"
                    value={values.complement}
                    onChange={handleChange}
                    error={touched.complement && Boolean(errors.complement)}
                    helperText={touched.complement && errors.complement?.toString()}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    disabled={true}
                    id="district"
                    name="district"
                    label="Bairro"
                    value={values.district}
                    onChange={handleChange}
                    error={touched.district && Boolean(errors.district)}
                    helperText={touched.district && errors.district?.toString()}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    disabled={true}
                    id="city"
                    name="city"
                    label="Município"
                    value={values.city}
                    onChange={handleChange}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city?.toString()}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    disabled={true}
                    id="uf"
                    name="uf"
                    label="UF"
                    value={values.uf}
                    onChange={handleChange}
                    error={touched.uf && Boolean(errors.uf)}
                    helperText={touched.uf && errors.uf?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="responsible_name"
                    name="responsible_name"
                    label="Nome do responsável"
                    value={values.responsible_name}
                    onChange={handleChange}
                    error={touched.responsible_name && Boolean(errors.responsible_name)}
                    helperText={touched.responsible_name && errors.responsible_name?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    id="responsible_birth"
                    name="responsible_birth"
                    label="Nascimento do responsável"
                    value={values.responsible_birth}
                    onChange={handleChange}
                    error={touched.responsible_birth && Boolean(errors.responsible_birth)}
                    helperText={touched.responsible_birth && errors.responsible_birth?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    id="responsible_cpf"
                    name="responsible_cpf"
                    label="CPF do responsável"
                    value={values.responsible_cpf}
                    onChange={handleChange}
                    InputProps={{ inputComponent: MaskCPF as any }}
                    error={touched.responsible_cpf && Boolean(errors.responsible_cpf)}
                    helperText={touched.responsible_cpf && errors.responsible_cpf?.toString()}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="responsible_email"
                    name="responsible_email"
                    label="Email do responsável"
                    value={values.responsible_email}
                    onChange={handleChange}
                    error={touched.responsible_email && Boolean(errors.responsible_email)}
                    helperText={touched.responsible_email && errors.responsible_email?.toString()}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    id="domain"
                    name="domain"
                    label="Domínio"
                    value={values.domain}
                    onChange={handleChange}
                    error={touched.domain && Boolean(errors.domain)}
                    helperText={touched.domain && errors.domain?.toString()}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    id="site"
                    name="site"
                    label="Site"
                    value={values.site}
                    onChange={handleChange}
                    error={touched.site && Boolean(errors.site)}
                    helperText={touched.site && errors.site?.toString()}
                  />
                </Grid>
                <Grid item md={4} xs={12}>

                  {
                    isTokenOnboardingSet ? (
                      <Grid container alignItems="center">
                        <Grid item>
                          <Switch
                            checked={values.using_onboarding}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: 'using_onboarding',
                                  value: event.target.checked,
                                },
                              });
                            }}
                            name="using_onboarding"
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          {values.using_onboarding ? 'Onboarding Ativado' : 'Onboarding Desativado'}
                        </Grid>
                      </Grid>
                    ) : (
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        onClick={createTokenOnboarding}
                        sx={{ height: '56px' }}
                      >
                        Criar Token Onboarding
                      </Button>
                    )
                  }


                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    required
                    type="text"
                    id="balance"
                    name="balance"
                    label="Limite monetário"
                    // value={formatMoney(values.balance)}
                    value={values.balance}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      )
                    }}
                    error={touched.balance && Boolean(errors.balance)}
                    helperText={touched.balance && errors.balance?.toString()}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Chip
                    label={'Check Match'}
                    onClick={() => setCheckMatch(!checkMatch)}
                    style={{ width: '100%', cursor: 'pointer' }}
                    variant="filled"
                    icon={<BsUiChecks />}
                    color={checkMatch ? "success" : "default"}
                  />
                </Grid>
              </Grid>

              {isTokenOnboardingSet &&
                (
                  <>
                    <Divider sx={{ marginY: 5 }} />
                    <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid item md={12} xs={12}>
                          <h3>Configuração Onboarding</h3>
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.logo"
                            name="layout_onboarding.layout.logo"
                            label="Logo Onboarding"
                            value={values.layout_onboarding.layout.logo}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.logo && Boolean(errors.layout_onboarding?.layout?.logo)}
                            helperText={touched.layout_onboarding?.layout?.logo && errors.layout_onboarding?.layout?.logo?.toString()}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.title_onboarding_color"
                            name="layout_onboarding.layout.title_onboarding_color"
                            label="Cor do titulo do onboarding"
                            value={values.layout_onboarding.layout.title_onboarding_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.title_onboarding_color && Boolean(errors.layout_onboarding?.layout?.title_onboarding_color)}
                            helperText={touched.layout_onboarding?.layout?.title_onboarding_color && errors.layout_onboarding?.layout?.title_onboarding_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.title_onboarding_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.title_section"
                            name="layout_onboarding.layout.title_section"
                            label="Cor do titulo da section"
                            value={values.layout_onboarding.layout.title_section}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.title_section && Boolean(errors.layout_onboarding?.layout?.title_section)}
                            helperText={touched.layout_onboarding?.layout?.title_section && errors.layout_onboarding?.layout?.title_section?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.title_section,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.card_section"
                            name="layout_onboarding.layout.card_section"
                            label="Cor do card da Seção"
                            value={values.layout_onboarding.layout.card_section}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.card_section && Boolean(errors.layout_onboarding?.layout?.card_section)}
                            helperText={touched.layout_onboarding?.layout?.card_section && errors.layout_onboarding?.layout?.card_section?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.card_section,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.primary_color"
                            name="layout_onboarding.layout.primary_color"
                            label="Cor primária"
                            value={values.layout_onboarding.layout.primary_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.primary_color && Boolean(errors.layout_onboarding?.layout?.primary_color)}
                            helperText={touched.layout_onboarding?.layout?.primary_color && errors.layout_onboarding?.layout?.primary_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.primary_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.second_color"
                            name="layout_onboarding.layout.second_color"
                            label="Cor secundária"
                            value={values.layout_onboarding.layout.second_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.second_color && Boolean(errors.layout_onboarding?.layout?.second_color)}
                            helperText={touched.layout_onboarding?.layout?.second_color && errors.layout_onboarding?.layout?.second_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.second_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.third_color"
                            name="layout_onboarding.layout.third_color"
                            label="Cor terciária"
                            value={values.layout_onboarding.layout.third_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.second_color && Boolean(errors.layout_onboarding?.layout?.second_color)}
                            helperText={touched.layout_onboarding?.layout?.second_color && errors.layout_onboarding?.layout?.second_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.third_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.text_color"
                            name="layout_onboarding.layout.text_color"
                            label="Cor do texto"
                            value={values.layout_onboarding.layout.text_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.text_color && Boolean(errors.layout_onboarding?.layout?.text_color)}
                            helperText={touched.layout_onboarding?.layout?.text_color && errors.layout_onboarding?.layout?.text_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.text_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.button_text_color"
                            name="layout_onboarding.layout.button_text_color"
                            label="Cor do texto do botão"
                            value={values.layout_onboarding.layout.button_text_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.button_text_color && Boolean(errors.layout_onboarding?.layout?.button_text_color)}
                            helperText={touched.layout_onboarding?.layout?.button_text_color && errors.layout_onboarding?.layout?.button_text_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.button_text_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.navbar_background_color"
                            name="layout_onboarding.layout.navbar_background_color"
                            label="Cor do fundo do navbar"
                            value={values.layout_onboarding.layout.navbar_background_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.navbar_background_color && Boolean(errors.layout_onboarding?.layout?.navbar_background_color)}
                            helperText={touched.layout_onboarding?.layout?.navbar_background_color && errors.layout_onboarding?.layout?.navbar_background_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.navbar_background_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.card_shadow_color"
                            name="layout_onboarding.layout.card_shadow_color"
                            label="Cor da sombra do card"
                            value={values.layout_onboarding.layout.card_shadow_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.card_shadow_color && Boolean(errors.layout_onboarding?.layout?.card_shadow_color)}
                            helperText={touched.layout_onboarding?.layout?.card_shadow_color && errors.layout_onboarding?.layout?.card_shadow_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.card_shadow_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.baseboard_color"
                            name="layout_onboarding.layout.baseboard_color"
                            label="Cor do rodapé"
                            value={values.layout_onboarding.layout.baseboard_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.baseboard_color && Boolean(errors.layout_onboarding?.layout?.baseboard_color)}
                            helperText={touched.layout_onboarding?.layout?.baseboard_color && errors.layout_onboarding?.layout?.baseboard_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.baseboard_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.baseboard_text_color"
                            name="layout_onboarding.layout.baseboard_text_color"
                            label="Cor do texto do rodapé"
                            value={values.layout_onboarding.layout.baseboard_text_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.baseboard_text_color && Boolean(errors.layout_onboarding?.layout?.baseboard_text_color)}
                            helperText={touched.layout_onboarding?.layout?.baseboard_text_color && errors.layout_onboarding?.layout?.baseboard_text_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.baseboard_text_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.card_color"
                            name="layout_onboarding.layout.card_color"
                            label="Cor do card"
                            value={values.layout_onboarding.layout.card_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.card_color && Boolean(errors.layout_onboarding?.layout?.card_color)}
                            helperText={touched.layout_onboarding?.layout?.card_color && errors.layout_onboarding?.layout?.card_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.card_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout.card_text_color"
                            name="layout_onboarding.layout.card_text_color"
                            label="Cor do texto do card"
                            value={values.layout_onboarding.layout.card_text_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout?.card_text_color && Boolean(errors.layout_onboarding?.layout?.card_text_color)}
                            helperText={touched.layout_onboarding?.layout?.card_text_color && errors.layout_onboarding?.layout?.card_text_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout.card_text_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout_email.logo_email"
                            name="layout_onboarding.layout_email.logo_email"
                            label="Logo para o email"
                            value={values.layout_onboarding.layout_email.logo_email}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout_email?.logo_email && Boolean(errors.layout_onboarding?.layout_email?.logo_email)}
                            helperText={touched.layout_onboarding?.layout_email?.logo_email && errors.layout_onboarding?.layout_email?.logo_email?.toString()}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout_email.title_color"
                            name="layout_onboarding.layout_email.title_color"
                            label="Cor do título"
                            value={values.layout_onboarding.layout_email.title_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout_email?.title_color && Boolean(errors.layout_onboarding?.layout_email?.title_color)}
                            helperText={touched.layout_onboarding?.layout_email?.title_color && errors.layout_onboarding?.layout_email?.title_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout_email.title_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout_email.title_background_color"
                            name="layout_onboarding.layout_email.title_background_color"
                            label="Cor do fundo do título"
                            value={values.layout_onboarding.layout_email.title_background_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout_email?.title_background_color && Boolean(errors.layout_onboarding?.layout_email?.title_background_color)}
                            helperText={touched.layout_onboarding?.layout_email?.title_background_color && errors.layout_onboarding?.layout_email?.title_background_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout_email.title_background_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout_email.text_button_color"
                            name="layout_onboarding.layout_email.text_button_color"
                            label="Cor do texto do botão"
                            value={values.layout_onboarding.layout_email.text_button_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout_email?.text_button_color && Boolean(errors.layout_onboarding?.layout_email?.text_button_color)}
                            helperText={touched.layout_onboarding?.layout_email?.text_button_color && errors.layout_onboarding?.layout_email?.text_button_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout_email?.text_button_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout_email.button_background_color"
                            name="layout_onboarding.layout_email.button_background_color"
                            label="Cor do fundo do botão"
                            value={values.layout_onboarding.layout_email.button_background_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout_email?.button_background_color && Boolean(errors.layout_onboarding?.layout_email?.button_background_color)}
                            helperText={touched.layout_onboarding?.layout_email?.button_background_color && errors.layout_onboarding?.layout_email?.button_background_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout_email?.button_background_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout_email.text_footer_color"
                            name="layout_onboarding.layout_email.text_footer_color"
                            label="Cor do fundo do botão"
                            value={values.layout_onboarding.layout_email.text_footer_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout_email?.text_footer_color && Boolean(errors.layout_onboarding?.layout_email?.text_footer_color)}
                            helperText={touched.layout_onboarding?.layout_email?.text_footer_color && errors.layout_onboarding?.layout_email?.text_footer_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout_email?.text_footer_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            id="layout_onboarding.layout_email.footer_background_color"
                            name="layout_onboarding.layout_email.footer_background_color"
                            label="Cor do fundo do botão"
                            value={values.layout_onboarding.layout_email.footer_background_color}
                            onChange={handleChange}
                            error={touched.layout_onboarding?.layout_email?.footer_background_color && Boolean(errors.layout_onboarding?.layout_email?.footer_background_color)}
                            helperText={touched.layout_onboarding?.layout_email?.footer_background_color && errors.layout_onboarding?.layout_email?.footer_background_color?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      backgroundColor: values.layout_onboarding.layout_email?.footer_background_color,
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                      </Grid>
                    </Box>
                    
                  </>
                )}

              <Grid
                item
                marginTop={5}
                alignItems="center"
                alignContent="center"
              >
                <Button
                  variant="contained"
                  size="large"
                  color="success"
                  type="submit"
                  disabled={creating}
                >
                  {id ? 'Atualizar' : 'Cadastrar'}
                </Button>
              </Grid>
            </form>

            <Divider sx={{ marginY: 5 }} />
            {
              id
                ? <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item md={12} xs={12}>
                    <h3>Tabela de preços</h3>
                  </Grid>
                  <Grid
                    item
                    md={12} xs={12}
                    marginBottom={2}
                  >
                    <Alert severity={editing ? "warning" : "success"}>
                      {
                        editing
                          ? "Você ainda não salvou as alterações!"
                          : "Tudo atualizado por aqui =)"
                      }
                    </Alert>
                  </Grid>
                  <List dense disablePadding sx={{ width: '100%' }}>
                    {
                      pricesList?.price_products.map((item, index) =>
                        <ListItem
                          key={`price-${item?.id}`}
                          sx={{ marginLeft: '10px' }}
                          disablePadding
                          color="warning"
                          secondaryAction={
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                md={4}
                                xs={2}
                              >
                                <TextField
                                  variant="filled"
                                  id={`price-${index}`}
                                  name="price"
                                  label="Preço"
                                  type="text"
                                  value={item?.price}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">R$</InputAdornment>
                                    ),
                                    inputMode: "decimal"
                                  }}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    onChangePrice(e, index)
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={2}
                              >
                                <TextField
                                  variant="filled"
                                  id={`match_price-${index}`}
                                  name="match_price"
                                  label="Preço do match"
                                  type="text"
                                  value={item?.match_price}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">R$</InputAdornment>
                                    ),
                                    inputMode: "decimal"
                                  }}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    onChangePrice(e, index)
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={1}
                              >
                                <IconButton edge="end" aria-label="delete" onClick={setAvailable(index)}>
                                  <Switch checked={item?.available} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          }
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                backgroundColor: `${item?.product__category === "1" ? LightMode.warning.main : item?.product__category === "2" ? LightMode.info.main : item?.product__category === "3" ? LightMode.success.main : LightMode.error.main}`
                              }}
                            >
                              {
                                item?.product__category === "1"
                                  ? <DirectionsCarIcon />
                                  : item?.product__category === "2"
                                    ? <AssignmentIndIcon />
                                    : item?.product__category === "3"
                                      ? <FactCheckIcon />
                                      : <BusinessIcon />
                              }
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={`${item?.product__name}`} secondary={item?.product__category_display} />
                        </ListItem>
                      )
                    }
                  </List>
                  <Grid
                    item
                    alignItems="center"
                    alignContent="center"
                  >
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      disabled={!editing}
                      disableElevation={true}
                      onClick={savePricesList}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
                : <></>
            }
          </>
          : <LoadingPage />
      }
    </>
  )
}